// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { AuthConfig } from '@auth0/auth0-angular';

const authConfig: AuthConfig = {
  domain: 'dev-o3p5ybq607tarq5q.us.auth0.com',
  clientId: 'S2agEfBrLTciTN7VsThVxMDa3ZAm2p82',
  authorizationParams: {
    redirect_uri: window.location.origin
  },
  httpInterceptor: {
    allowedList: [
      {
        uri: 'https://axle-dev.herokuapp.com/api/v1/private*'
      }
    ]
  },
  skipRedirectCallback: window.location.pathname === '/preferences'
};

export const environment = {
  env: 'dev',
  production: false,
  herokuLink: 'https://axle-dev.herokuapp.com/api/v1/private',
  herokuLinkPublic: 'https://axle-dev.herokuapp.com/api/v1/public',
  auth0: authConfig
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
