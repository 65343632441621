import { withDevtools } from '../../functions/redux-dev-tools';
import { Theme, themes } from '../../models/theme.models';
import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState
} from '@ngrx/signals';

export interface ThemeState {
  currentTheme: Theme;
  darkMode: boolean;
}

export const initialState: ThemeState = {
  currentTheme: 'AXLE',
  darkMode: false
};

export const ThemeStore = signalStore(
  { providedIn: 'root' },
  withDevtools('theme'),
  withState(initialState),
  withMethods((store) => ({
    updateTheme: (currentTheme: Theme) => patchState(store, { currentTheme }),
    updateDarkMode: (darkMode: boolean) => patchState(store, { darkMode })
  })),
  withComputed(({ currentTheme, darkMode }) => ({
    currentTheme: computed(() => themes[currentTheme()]),
    homeLogo: computed(() => {
      const useDarkMode =
        darkMode() || ['ASBURY', 'CLEAR_STREET'].includes(currentTheme());
      return `https://cdn.axleaccess.com/assets/axle_${useDarkMode ? 'dark' : 'light'}_mode_multi_logo_home.svg`;
    }),
    loginLogo: computed(() => {
      const useDarkMode =
        darkMode() || ['ASBURY', 'CLEAR_STREET'].includes(currentTheme());
      return `https://cdn.axleaccess.com/assets/Axle_Login_Logo_${useDarkMode ? 'Dark' : 'Light'}.svg`;
    })
  }))
);
