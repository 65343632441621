import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';

import { environment } from 'src/environments/environment';

export function whitelistInterceptorFn(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
) {
  const allowedDomains = [
    'axle-dev.herokuapp.com',
    'axle-qa.herokuapp.com',
    'api.axleaccess.com',
    `https://${environment.auth0.domain}/dbconnections/change_password`
  ];

  if (environment.env === 'local') {
    allowedDomains.push('localhost:6969');
  }

  const url = req.url.toLowerCase();
  const isAllowed = allowedDomains.some((domain) => url.includes(domain));

  const sessionTokenKey = 'axle_session_token';
  const sessionToken = sessionStorage.getItem(sessionTokenKey) || uuidv4();

  sessionStorage.setItem(sessionTokenKey, sessionToken);

  const authReq = req.clone({
    headers: req.headers.set('Axle-Session-Id', sessionToken)
  });

  if (isAllowed) {
    return next(authReq);
  } else {
    throw new Error('Unauthorized request to ' + url);
  }
}
