import { AppComponent } from './app/app.component';
import { Action } from './app/shared/functions/redux-dev-tools';
import {
  determineAuthRedirectTo,
  determineRedirectTo,
  parentAuthGuardFn
} from './app/shared/guard/authentication.guards';
import { whitelistInterceptorFn } from './app/shared/interceptors/shared-interceptors';
import { TranslocoHttpLoader } from './app/transloco-loader';
import { environment } from './environments/environment';
import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors
} from '@angular/common/http';
import {
  ApplicationRef,
  ErrorHandler,
  importProvidersFrom
} from '@angular/core';
import {
  bootstrapApplication,
  enableDebugTools
} from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { Routes, provideRouter, withViewTransitions } from '@angular/router';
import { authHttpInterceptorFn, provideAuth0 } from '@auth0/auth0-angular';
import { provideTransloco } from '@ngneat/transloco';
import {
  browserTracingIntegration,
  createErrorHandler,
  init
} from '@sentry/angular-ivy';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Intercom: any;
    __REDUX_DEVTOOLS_EXTENSION__:
      | {
          connect: (options: { name: string }) => {
            send: (action: Action, state: Record<string, unknown>) => void;
          };
        }
      | undefined;
  }
}

const routes: Routes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./app/features/login/login/login.component').then(
        (c) => c.AxleLoginComponent
      )
  },
  {
    path: 'password',
    children: [
      {
        path: 'reset',
        loadComponent: () =>
          import(
            './app/features/reset-password/container/reset-password-container.component'
          ).then((c) => c.AxleResetPasswordContainerComponent)
      }
    ]
  },
  {
    path: 'admin-v777rpNsg_5hjfsdkhjsdfkhjsdfkjhsdfmuegcko6F4w',
    loadComponent: () =>
      import(
        './app/features/admin/admin-container/admin-container.component'
      ).then((c) => c.AxleAdminContainerComponent),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'home',
    loadComponent: () =>
      import('./app/features/home/container/home-container.component').then(
        (c) => c.AxleHomeContainerComponent
      ),
    canActivate: [parentAuthGuardFn],
    children: [
      {
        path: '',
        loadComponent: () =>
          import(
            './app/features/home/primary-container/home-primary-container.component'
          ).then((c) => c.AxleHomePrimaryContainerComponent)
      },
      {
        path: 'uploadMeetings',
        loadComponent: () =>
          import(
            './app/features/upload-meetings/container/upload-meetings-container.component'
          ).then((c) => c.AxleUploadMeetingsComponent)
      },
      {
        path: 'outstandingRequests',
        loadComponent: () =>
          import(
            './app/features/outstading-requests/container/outstanding-requests-container.component'
          ).then((c) => c.AxleOutstandingRequestsContainerComponent)
      }
    ]
  },
  {
    path: 'contacts',
    loadComponent: () =>
      import(
        './app/features/contacts/container/axle-contacts-container.component'
      ).then((c) => c.AxleContactsContainerComponent),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'events',
    loadComponent: () =>
      import('./app/features/events/container/events-container.component').then(
        (c) => c.AxleEventsContainerComponent
      ),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'reporting',
    loadComponent: () =>
      import(
        './app/features/reporting/container/reporting-container.component'
      ).then((c) => c.AxleReportingContainerComponent),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'profile',
    loadComponent: () =>
      import(
        './app/features/profile/container/profile-container.component'
      ).then((c) => c.AxleProfileContainerComponent),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'preferences',
    loadComponent: () =>
      import(
        './app/features/preferences/preferences-container/preferences-container.component'
      ).then((c) => c.AxlePreferencesContainerComponent),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'playground',
    loadComponent: () =>
      import('./app/features/testing/axle-testing/axle-testing.component').then(
        (c) => c.AxlePlaygroundComponent
      ),
    canActivate: [parentAuthGuardFn]
  },
  {
    path: 'auth',
    children: [],
    // component: SpinnerOverlayComponent,
    canActivate: [determineAuthRedirectTo]
  },
  {
    path: '**',
    pathMatch: 'full',
    children: [],
    canActivate: [determineRedirectTo]
  }
];

init({
  dsn: 'https://4ad21a6729c420b2d3cac55ebf45161c@o4506972498952192.ingest.us.sentry.io/4506972507996160',
  integrations: [browserTracingIntegration()], //, replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    'localhost',
    'https://127.0.0.1:8443',
    'axle-dev.herokuapp.com',
    'axle-qa.herokuapp.com',
    'api.axleaccess.com'
  ]
  // Session Replay
  // replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

window.Intercom('boot', {
  api_base: 'https://api-iam.intercom.io',
  app_id: 'ixtfpdrt'
});

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(HttpClientModule),
    provideAnimationsAsync(),
    provideRouter(routes, withViewTransitions()),
    provideTransloco({
      config: {
        availableLangs: [
          {
            id: 'en',
            label: 'English'
          },
          { id: 'es', label: 'Spanish' },
          { id: 'fr', label: 'French' }
        ],
        defaultLang: 'en',
        reRenderOnLangChange: true
        // prodMode: environment.production
      },
      loader: TranslocoHttpLoader
    }),
    provideAuth0(environment.auth0),
    provideHttpClient(
      withInterceptors([authHttpInterceptorFn, whitelistInterceptorFn])
    ),
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    }
  ]
}).then((module) =>
  enableDebugTools(module.injector.get(ApplicationRef).components[0])
);
