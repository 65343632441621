import { AxleHomeContainerComponent } from './features/home/container/home-container.component';
import { SubscriptionHandler } from './shared/classes/subscription-handler';
import { AxleTileButtonComponent } from './shared/components/buttons/axle-tile-button/axle-tile-button.component';
import { AxleFilledButtonComponent } from './shared/components/buttons/filled-button/axle-filled-button.component';
import { withDevtools } from './shared/functions/redux-dev-tools';
import { AxleToastService } from './shared/services/toast/axle-toast.service';
import { ThemeStore } from './shared/state/theme/theme.state';
import { DOCUMENT } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  Component,
  ErrorHandler,
  OnInit,
  Renderer2,
  inject
} from '@angular/core';
import { Router, RouterLinkWithHref, RouterOutlet } from '@angular/router';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { TraceService, createErrorHandler } from '@sentry/angular-ivy';
import { EditorModule } from '@tinymce/tinymce-angular';
import posthog from 'posthog-js';

if (
  !window.location.host.includes('127.0.0.1') &&
  !window.location.host.includes('localhost')
) {
  posthog.init('phc_B44LvJW3JBsMdNAM5rjNBXg9iCml1SACjcMsKhU6ve0', {
    api_host: 'https://app.posthog.com',
    secure_cookie: true
  });
}

export interface RouterState {
  previousUrl: string | null;
  currentUrl: string | null;
}

export const RouterStore = signalStore(
  { providedIn: 'root' },
  withDevtools('router'),
  withState<RouterState>({
    previousUrl: null,
    currentUrl: null
  }),
  withMethods((store) => ({
    store: (previousUrl: string) => patchState(store, { previousUrl })
  }))
);

@Component({
  selector: 'axle-root',
  standalone: true,
  imports: [
    AxleFilledButtonComponent,
    AxleTileButtonComponent,
    HttpClientModule,
    AxleHomeContainerComponent,
    RouterOutlet,
    RouterLinkWithHref,
    EditorModule
  ],
  providers: [
    AuthHttpInterceptor,
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: true
      })
    }
    // RefreshDataService,
  ],
  templateUrl: './app.component.html'
})
export class AppComponent extends SubscriptionHandler implements OnInit {
  toastService = inject(AxleToastService);
  private _themeStore = inject(ThemeStore);
  private _router = inject(Router);
  private _renderer = inject(Renderer2);
  private _document: Document = inject(DOCUMENT);
  private _routerStore = inject(RouterStore);

  currentTheme = this._themeStore.currentTheme;

  ngOnInit() {
    // this.addSubscription(
    //   this._store.select(getUserInfo).subscribe((userInfo) => {
    //     if (userInfo) {
    //       window.Atlas.call('identify', {
    //         userId: userInfo?.id,
    //         name: `${userInfo?.firstname ?? undefined} ${userInfo?.lastname ?? undefined}`,
    //         email: userInfo?.email,
    //         userHash: userInfo?.hashedValues
    //       });

    //       posthog.identify(userInfo.id);
    //     }
    //   })
    // );

    this._renderer.addClass(this._document.body, this.currentTheme().bgLight);
    this._renderer.addClass(this._document.body, this.currentTheme().bgDark);

    const themePreference = localStorage.getItem('axle-theme-preference');

    if (themePreference === 'dark' || themePreference === 'light') {
      this._themeStore.updateDarkMode(themePreference === 'dark');

      if (themePreference === 'dark') {
        this._document.documentElement.classList.add('dark');
      } else {
        this._document.documentElement.classList.remove('dark');
      }
    } else {
      const event = window.matchMedia('(prefers-color-scheme: dark)');

      if (event) {
        this._themeStore.updateDarkMode(event.matches);
        localStorage.setItem(
          'axle-theme-preference',
          event.matches ? 'dark' : 'light'
        );

        if (event.matches) {
          this._document.documentElement.classList.add('dark');
        } else {
          this._document.documentElement.classList.remove('dark');
        }
      }
    }

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        this._themeStore.updateDarkMode(event.matches);
        localStorage.setItem(
          'axle-theme-preference',
          event.matches ? 'dark' : 'light'
        );

        if (event.matches) {
          this._document.documentElement.classList.add('dark');
        } else {
          this._document.documentElement.classList.remove('dark');
        }
      });
  }

  removeToast(index: number) {
    this.toastService.remove(index);
  }

  chatButtonClicked() {}
}
