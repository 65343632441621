import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive()
export abstract class SubscriptionHandler implements OnDestroy {
  private _subscriptions = new Subscription();

  protected addSubscription(subscription: Subscription) {
    this._subscriptions.add(subscription);
  }

  protected unsubscribe() {
    this._subscriptions.unsubscribe();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
