export type Theme = 'CLEAR_STREET' | 'ASBURY' | 'AXLE';

export const themes = {
  CLEAR_STREET: {
    bg: "bg-[url('https://app.clearstreet.io/_next/static/media/bg.34f86c7f.jpg')]",
    bgLight:
      "bg-[url('https://app.clearstreet.io/_next/static/media/bg.34f86c7f.jpg')]",
    bgDark:
      "bg-[url('https://app.clearstreet.io/_next/static/media/bg.34f86c7f.jpg')]",
    primaryTextColor: 'text-white',
    secondaryTextColor: 'text-secondary-400'
  },
  ASBURY: {
    bg: "bg-[url('https://asburyir.com/wp-content/plugins/coming-soon-maintenance-mode/templates/images/temp-1.webp')]",
    bgLight:
      "bg-[url('https://asburyir.com/wp-content/plugins/coming-soon-maintenance-mode/templates/images/temp-1.webp')]",
    bgDark:
      "bg-[url('https://asburyir.com/wp-content/plugins/coming-soon-maintenance-mode/templates/images/temp-1.webp')]",
    primaryTextColor: 'text-white',
    secondaryTextColor: 'text-secondary-400'
  },
  AXLE: {
    bg: 'bg-secondary-50 dark:bg-secondary-800',
    bgLight: 'bg-secondary-50',
    bgDark: 'dark:bg-secondary-800',
    primaryTextColor: 'text-black dark:text-secondary-50',
    secondaryTextColor: 'text-secondary-400'
  }
};
