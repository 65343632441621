import { Injectable, signal } from '@angular/core';

export type ToastType = 'success' | 'error';

export interface Toast {
  message: string;
  duration: number;
  type: ToastType;
}

@Injectable({
  providedIn: 'root'
})
export class AxleToastService {
  toasts = signal<Toast[]>([]);

  add(message: string, duration: number = 3000, type: ToastType = 'success') {
    const toast: Toast = { message, duration, type };
    this.toasts.update((toasts) => [...toasts, toast]);
    setTimeout(() => this.remove(0), toast.duration);
  }

  remove(i: number) {
    this.toasts.update((toasts) =>
      [...toasts].filter((_, index) => index !== i)
    );
  }
}
