<router-outlet></router-outlet>

<!-- Toast Container -->
<div
  style="z-index: 2147482000"
  class="fixed left-1/2 top-2.5 flex -translate-x-1/2 transform flex-col gap-2">
  @for (toast of toastService.toasts(); track $index) {
    <div
      class="axle-bg-white flex items-center gap-3.5 rounded-xl px-4 py-2 shadow">
      @if (toast.type === 'success') {
        <i class="bi bi-check-circle-fill text-lg text-primary-500"></i>
      } @else if (toast.type === 'error') {
        <i class="bi bi-x-circle-fill text-lg text-error-500"></i>
      }
      <p class="axle-text-secondary-900">{{ toast.message }}</p>
      <button
        type="button"
        (click)="removeToast($index)"
        class="-mx-1.5 -my-1.5 ms-auto inline-flex h-8 w-8 items-center justify-center rounded-lg p-1.5 text-secondary-400 hover:text-secondary-900 dark:text-secondary-500 dark:hover:text-white"
        aria-label="Close">
        <span class="sr-only">Close</span>
        <i class="bi bi-x text-xl"></i>
      </button>
    </div>
  }
</div>
